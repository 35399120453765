import React, { useEffect, useState } from 'react'
import { Scrollama, Step } from 'react-scrollama2'
import { Box } from 'rebass/styled-components'
import Layout from '../layouts/main'
import Hot16Logo from '../components/hot16/Details/Hot16Logo'
import Chart from '../components/hot16/hot16'
import styled from 'styled-components'
import slidesContent, {
  getSlide,
} from '../components/hot16/scroll/slidesContentEnglish'
import { zoomControlCallback } from '../components/hot16/scroll/helpers'
import useDebounce from '../hooks/useDebounce'
import 'intersection-observer'
import { swipedEvents } from '../hooks/swipedEvents'
import { SEOhot16PL } from '../components/SEOhot16'

const noScroll = (top) => () => {
  window.scrollTo({ top })
}

const mobileLogger = (m) => {
  const log = document.getElementById('log')
  log.innerHTML = `<p>${m}</p>` + log.innerHTML
}

const ScrollamaDemo = () => {
  const [isBrowser, setIsBrowser] = useState(typeof window !== 'undefined')
  const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0)
  const slideHeight = isBrowser ? window.innerHeight / 2 : '50vh'
  const isDesktop = width > 768
  const [height, setHeight] = useState(isBrowser ? 2 * window.innerHeight : 0)
  const [lastUpdate, setLastUpdate] = useState(new Date().valueOf())
  const isEnoughTimeElapsed = () => new Date().valueOf() - lastUpdate > 1000

  const onSwipeUp = (index) => () => {
    scrollToStep(index + 1)
  }
  const onSwipeDown = (index) => () => {
    scrollToStep(index - 1)
  }

  const generateListeners = (array) => {
    return array.map((e, i) => ({
      onSwipeUp: onSwipeUp(i),
      onSwipeDown: onSwipeDown(i),
    }))
  }
  const mobileListeners = generateListeners(slidesContent)

  const updateMobileListeners = (currentStep) => {
    if (!isBrowser) return

    mobileListeners
      .filter((e, i) => i !== currentStep)
      .forEach((e) => {
        window.removeEventListener('swiped-up', e.onSwipeUp)
        window.removeEventListener('swiped-down', e.onSwipeDown)
      })

    setTimeout(() => {
      window.addEventListener(
        'swiped-up',
        mobileListeners[currentStep].onSwipeUp,
        {
          once: true,
        }
      )
      window.addEventListener(
        'swiped-down',
        mobileListeners[currentStep].onSwipeDown,
        {
          once: true,
        }
      )
    }, 500)
  }

  const scrollToStep = (step) => {
    if (step < 1 || step > slidesContent.length + 1 || !isEnoughTimeElapsed()) {
      return
    }
    setCurrentStepIndex(step)
    const top =
      document.getElementById(`scrollama2-${step}`).offsetTop + slideHeight / 2

    window.scrollTo({
      top,
      behavior: 'smooth',
    })
    if (!isDesktop) {
      updateMobileListeners(step)
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
      setIsBrowser(true)

      if (!isDesktop) {
        swipedEvents(window, document)

        updateMobileListeners(0)
      }
    }
  }, [])

  const [currentStepIndex, setCurrentStepIndex] = useState(0)
  const currentStep = getSlide(currentStepIndex)

  const onStepEnter = (w) => {
    const { data, element } = w
    const delta = currentStepIndex - data
    if (Math.abs(delta) > 1 && data != 0) {
      const stepToSet = currentStepIndex + Math.sign(delta)
      scrollToStep(stepToSet)
    }

    setCurrentStepIndex(data)

    if (data > 0) {
      const listener = noScroll(element.offsetTop + slideHeight - 1)
      window.addEventListener('scroll', listener)
      setTimeout(() => window.removeEventListener('scroll', listener), 1500)
    }
  }

  const debouncedStepIndex = useDebounce(currentStepIndex, 100)

  useEffect(() => {
    const currentStep = getSlide(debouncedStepIndex)
    if (currentStep.slideCallback) {
      currentStep.slideCallback(width, height)
    }
  }, [debouncedStepIndex])

  const isLastSlide =
    currentStepIndex && currentStepIndex === slidesContent.length - 1
  return (
    <Layout preventScroll={!isDesktop}>
      <SEOhot16PL en />

      <div id={'log'} style={{ position: 'fixed' }} />
      <div style={{ marginTop: slideHeight }} />

      <Logo
        isTop={currentStepIndex == 0}
        onClick={() => {
          window.scrollTo(0, 0)
          setCurrentStepIndex(0)
        }}
        style={{
          cursor: 'pointer',
          zIndex: 666,
          opacity: currentStep.noLogo ? 0.1 : 1,
        }}
      />

      <Scrollama onStepEnter={onStepEnter} offset={0}>
        {slidesContent.map((_, stepIndex) => (
          <Step
            data={stepIndex}
            key={stepIndex}
            id={'step-anchor-' + stepIndex}
          >
            <div
              href={stepIndex}
              style={{
                height: slideHeight,
                scrollSnapAlign: 'start',
                scrollSnapStop: 'always',
                // border: '1px solid black',
              }}
            />
          </Step>
        ))}
      </Scrollama>

      <Box
        style={{
          position: 'fixed',
          top: 0,
          transition: '0.1s all',
          zIndex: 20,
        }}
      >
        {isBrowser ? (
          <Chart
            hideDetails={!isLastSlide}
            // disableInteraction={!isLastSlide}
            disableInteraction={true}
            depthFilter={currentStep.depthToShow}
            filterFunction={currentStep.filter}
            // initialTransform={transformScale(2)(width, height * 3)}
            clicked={currentStep.clicked}
            clickedZoom={currentStep.clickedZoom}
          />
        ) : null}
      </Box>
      {!isLastSlide && !currentStep.noLogo && (
        <Controls>
          <span
            onClick={() => {
              scrollToStep(Math.max(currentStepIndex - 1, 0))
            }}
            style={{
              cursor: 'pointer',
              opacity: currentStepIndex == 0 ? 0 : 1,
            }}
          >
            {'<'}
          </span>
          {currentStepIndex}
          {isBrowser && (
            <AppearSpan
              onClick={() => {
                scrollToStep(currentStepIndex + 1)
              }}
            >
              {'>'}
            </AppearSpan>
          )}
        </Controls>
      )}
      {isLastSlide && (
        <h1
          style={{
            position: 'fixed',
            bottom: 0,
            fontSize: isDesktop ? '4em' : '1em',
            marginBottom: 0,
            left: 20,
            zIndex: 1000,
          }}
        >
          <span
            onClick={() => {
              zoomControlCallback(1)(window.innerWidth, window.innerHeight)
              setCurrentStepIndex(Math.max(currentStepIndex - 1, 0))
            }}
            style={{ cursor: 'pointer' }}
          >
            {'^ wróć'}
          </span>
        </h1>
      )}
      <div style={{ zIndex: 200 }}>{currentStep.component}</div>
    </Layout>
  )
}

const Controls = styled.h1`
  position: fixed;
  bottom: 20px;
  font-size: ${(props) => (props.isDesktop ? '4em' : '2em')};
  margin-bottom: 0;
  left: ${(props) => (props.isDesktop ? 20 : 5)}px;
  z-index: 1000;
`
const Logo = styled(Hot16Logo)`
  > * {
    position: -webkit-sticky;
    position: sticky;
    transition: 0.5s all;
    margin-left: 20px;
    z-index: 200;
  }
`

const AppearSpan = styled.span`
  animation: appear2 1s infinite alternate;
  cursor: pointer;
  @keyframes appear2 {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

export default ScrollamaDemo

//
// function scrollToPreventBounce(htmlElement) {
//   const { scrollTop, offsetHeight, scrollHeight } = htmlElement
//
//   // If at top, bump down 1px
//   if (scrollTop <= 0) {
//     htmlElement.scrollTo(0, 1)
//     return
//   }
//
//   // If at bottom, bump up 1px
//   if (scrollTop + offsetHeight >= scrollHeight) {
//     htmlElement.scrollTo(0, scrollHeight - offsetHeight - 1)
//   }
// }
//
// function afterRender(htmlElement) {
//   htmlElement.addEventListener('touchstart', scrollToPreventBounce)
// }
//
// function beforeRemove(htmlElement) {
//   htmlElement.removeEventListener('touchstart', scrollToPreventBounce)
// }
//
// const preventDefault = (e) => e.preventDefault()
