import React from 'react'
import { Box, Button, Text, Flex, Heading } from 'rebass/styled-components'

import * as d3 from 'd3'
import { Link } from 'rebass'
import { H1, Overlay, Slide } from './Slide'
import {
  isDesktop,
  resetCircles,
  transformScale,
  zoomControlCallback,
} from './helpers'
import { links, linksPoliticians, linksProfessions } from './linksEnglish'

const slides = [
  {
    depthToShow: 100,
    slideCallback: (w, h) => {
      zoomControlCallback(1.5, 500)(w, h)
    },
    component: <Slide></Slide>,
  },
  {
    slideCallback: (w, h) => {
      zoomControlCallback(1)(w, h)
      resetCircles()
    },
    depthToShow: 100,
    component: <Slide></Slide>,
  },
  {
    slideCallback: (w, h) => {
      zoomControlCallback(1, 1)(w, h)
    },
    component: (
      <Slide>
        <Overlay>
          <H1 fontSize={[2, 6]}>
            #hot16challenge2 started on 28 April 2020, two weeks after Poland
            had closed its borders due to coronavirus lockdown
          </H1>
        </Overlay>
      </Slide>
    ),
  },
  {
    slideCallback: (w, h) => {
      zoomControlCallback(1)(w, h)
      resetCircles()
    },
    component: (
      <Slide>
        <Overlay>
          <H1 fontSize={[2, 6]}>
            The idea behind it was to collect money and support underfunded
            public health system in the fight against the pandemic
          </H1>
        </Overlay>
      </Slide>
    ),
  },
  // {
  //   depthToShow: 100,
  //   component: <Slide></Slide>,
  // },
  {
    // depthToShow: 1,
    slideCallback: (w, h) => {
      zoomControlCallback(1)(w, h)
      d3.selectAll('circle').transition().duration(750).attr('r', 1)
    },
    component: (
      <Slide>
        <p>
          It refers to #hot16challenge from a few years ago, which was a
          rappers' answer to the then popular #iceBucketChallenge
        </p>
      </Slide>
    ),
  },
  {
    slideCallback: (width, height) => {
      // d3.selectAll('path').transition().duration(250).attr('stroke-width', 0)
      // d3.select('#leaf-1')
      //   .transition()
      //   .duration(750)
      //   .attr('r', isDesktop(width) ? 20 : 10)
      zoomControlCallback(isDesktop(width) ? 10 : 8)(width - 200, height - 200)
    },
    clicked: 'Solar',
    component: (
      <Slide>
        <Overlay>
          <H1 fontSize={[2, 6]}>
            It was all started by a Polish rapper Solar...
          </H1>
        </Overlay>
      </Slide>
    ),
  },
  {
    depthToShow: 1,
    clicked: 'Solar',
    filter: (e) => true,
    slideCallback: (width, height) => {
      zoomControlCallback(isDesktop(width) ? 8 : 4)(width - 200, height - 200)
    },
    // transform: transformScale(20),
    component: (
      <Slide>
        <p>
          …who records his 16 bars on April 28 and calls out to support the
          cause on www.siepomaga.pl platform.
        </p>
        He nominates other Polish rappers - Bedoes, Taco Hemingway, Białas, Mata
        and American Mike Posner to record their songs within 72 hours.
      </Slide>
    ),
  },
  // {
  //   depthToShow: 1,
  //   clicked: 'Solar',
  //   filter: (e) => true,
  //   component: (
  //     <Slide>
  //       <p>
  //         28 kwietnia nagrywa 16 wersów i wyzywa do wsparcia zbiórki na portalu
  //         siepomaga.pl.
  //       </p>
  //       Nominuje Bedoesa, Taco Hemingwaya, Białasa, Matę oraz Mike Posnera do
  //       nagrania odpowiedzi w ciągu 72 godzin.
  //     </Slide>
  //   ),
  // },
  {
    depthToShow: 2,
    slideCallback: (w, h) => {
      zoomControlCallback(isDesktop(w) ? 5 : 4)(
        isDesktop(w) ? w - 630 : w - 230,
        h
      )
    },
    component: (
      <Slide>
        On May 1st Taco and Mata respond with their records, on May 2nd - Bedoes
        and Białas
      </Slide>
    ),
  },
  {
    depthToShow: 3,
    // slideCallback: (w, h) => {
    //   zoomControlCallback(isDesktop(w) ? 5 : 4)(
    //     isDesktop(w) ? w - 630 : w - 230,
    //     h
    //   )
    // },
    clicked: 'Taco Hemingway',
    component: (
      <Slide>They nominate other artists to take up the challenge</Slide>
    ),
  },
  {
    depthToShow: 3,
    clicked: 'Taconafide',
    slideCallback: (w, h) => {
      zoomControlCallback(3)(isDesktop(w) ? w : w - 150, h)
    },
    component: <Slide>Taco nominates Taconafide</Slide>,
  },
  {
    depthToShow: 4,
    slideCallback: (w, h) => {
      zoomControlCallback(2.5)(isDesktop(w) ? w : w - 120, h)
    },
    clicked: 'Dawid Podsiadło',
    clickedZoom: 3,
    component: (
      <Slide>
        Taconafide nominates one of most popular pop artists in Poland - Dawid
        Podsiadło
      </Slide>
    ),
  },
  {
    depthToShow: 5,
    slideCallback: (w, h) => {
      zoomControlCallback(2)(isDesktop(w) ? w : w - 120, h)
    },
    clicked: 'Monika Brodka',
    component: (
      <Slide>
        His recording and nomination for Monika Brodka, another acclaimed pop
        star exposes the challenge to a wider public
      </Slide>
    ),
  },
  {
    depthToShow: 8,
    // filter: (e) => false,
    // transform: transformScale(10),
    slideCallback: (w, h) => {
      // d3.selectAll('circle').transition().duration(750).attr('fill', '#555')
      zoomControlCallback(isDesktop(w) ? 2 : 2.5)(
        isDesktop(w) ? w - 200 : w - 50,
        isDesktop(h) ? h - 200 : h - 200
      )
    },
    clicked: 'Majka Jeżowska',
    component: <Slide>Then other musicians join the challenge</Slide>,
  },
  {
    depthToShow: 9,
    slideCallback: (width, height) => {
      zoomControlCallback(1.8)(width, height)
      d3.selectAll('#zoom text').remove()
      d3.selectAll('circle')
        .transition()
        .duration(750)
        .attr('r', isDesktop(width) ? 5 : 1)
    },
    // clicked: 'Monika Brodka',
    // clickedZoom: 3,
    component: (
      <Slide>
        <Overlay>
          <br />
          <H1 fontSize={[2, 6]}>
            More and more people joined, such as Mata's father, a prominent law
            professor Marcin Matczak, bodybuilder Michał Karmowski or
            controversial activist Zbigniew Stonoga.
          </H1>
        </Overlay>
      </Slide>
    ),
  },
  // {
  //   depthToShow: 10,
  //   // filter: (e) => false,
  //   // transform: transformScale(10),
  //   slideCallback: (w, h) => {
  //     resetCircles()
  //     // zoomControlCallback(1)(w, h)
  //     d3.selectAll('#zoom text').remove()
  //   },
  //   component: (
  //     <Slide>Nagle hiphopowe kawałki zaczynają nagrywać politycy...</Slide>
  //   ),
  // },
  {
    depthToShow: 12,
    slideCallback: (w, h) => {
      return zoomControlCallback(isDesktop(w) ? 1.3 : 2)(
        w - 200,
        h - (isDesktop(w) ? 100 : 0)
      )
    },
    clicked: 'Adam Bodnar',
    component: (
      <Slide>
        Suddenly hip-hop tracks were recorded by politicians
        <br />
        and other public figures, such as
        <i style={{ color: 'red' }}>Polish Ombudsman</i>
      </Slide>
    ),
  },
  // {
  //   depthToShow: 16,
  //   slideCallback: (w, h) => {
  //     resetCircles()
  //     zoomControlCallback(1)(w, h)
  //     // TODO mark politicians
  //     d3.selectAll('#zoom text').remove()
  //   },
  //   component: <Slide></Slide>,
  // },
  {
    depthToShow: 100,
    // clicked: 'Andrzej Duda',
    slideCallback: resetCircles,
    component: (
      <Slide>
        <Overlay>
          <H1 fontSize={[2, 6]}>
            After 2 weeks from the beginning of the challenge, even the
            President of Poland recorded his song, shocking the public with
            mysterious lyrics about
          </H1>
          <H1 fontSize={[2, 6]}>
            <i style={{ color: 'red' }}>"the sharp shadow of fog"</i>
          </H1>
        </Overlay>
      </Slide>
    ),
  },
  {
    depthToShow: 100,
    clicked: 'Andrzej Duda',
    slideCallback: (w, h) => {
      zoomControlCallback(2)(
        isDesktop(w) ? w + 700 : w + 200,
        isDesktop(w) ? h + 700 : h + 200
      )
    },
    component: <Slide></Slide>,
  },
  {
    depthToShow: 100,
    slideCallback: (w, h) => {
      zoomControlCallback(1)(w, h)
      d3.selectAll('#zoom text').remove()
      resetCircles()
    },
    noLogo: true,
    component: (
      <Slide>
        <Overlay>
          <h1>The challenge was taken up by thousands of people.</h1>
          <Box style={{ maxWidth: '100%', width: 768 }}>
            <Text fontSize={[1, 2]}>
              People of various professions:
              <br />
              <span style={{ width: '100%', overflowWrap: 'break-word' }}>
                {linksProfessions.map((link, i) => (
                  <span>
                    <Link href={link.href} target={'_blank'}>
                      {link.name}
                    </Link>
                    {i == linksProfessions.length - 1 ? '.' : ', '}
                  </span>
                ))}
              </span>
            </Text>
            <Text fontSize={[1, 2]} mt={2}>
              {'Politicians '}
              <Link href={linksPoliticians[0].href} target={'_blank'}>
                from the left
              </Link>{' '}
              and
              <span>
                {' '}
                <Link href={linksPoliticians[1].href} target={'_blank'}>
                  the right wings
                </Link>
                .
              </span>
            </Text>
            <Text fontSize={[1, 2]} mt={2} mb={2}>
              {'And many other people from outside the hip hop world '}
              {links.map((link, i) => (
                <span>
                  <Link href={link.href} target={'_blank'}>
                    {link.name}
                  </Link>
                  {i == links.length - 1 ? '.' : ', '}
                </span>
              ))}
            </Text>
            <br />
            And thousands more.
          </Box>
        </Overlay>
      </Slide>
    ),
  },
  {
    component: (
      <Slide>
        <Overlay>
          <Text>Many people disliked the challenge.</Text>
          <Text>
            They criticized the lyrics, the lack of focus on the goal of the
            challenge - supporting public health system workers in those hard
            times, politicians and companies trying to use it to gain extra
            publicity.
          </Text>
        </Overlay>
      </Slide>
    ),
  },
  {
    noLogo: true,
    component: (
      <Slide>
        <Overlay>
          <h1 style={{ marginBottom: 0 }}>Over 3,5m PLN was collected</h1>
          <Text style={{ marginBottom: 20 }}>
            (which converts to a little less than a million dollars)
          </Text>
          <Text style={{ marginBottom: 20 }}>Was it a lot?</Text>
          <Text>
            Surely not enough to solve all the public health system issues.
          </Text>
          <Text>Less than $0.0002 per view.</Text>
          <Text>
            On the other hand it was much more than the initial target
            of&nbsp;1m&nbsp;PLN.
          </Text>
          <Text>
            To put it into perspective - one of the most popular charities in
            Poland (WOŚP) in its annual event raised 186m PLN this year.
          </Text>
          <Text>It seems to be a good score if we take that into account.</Text>
        </Overlay>
      </Slide>
    ),
  },
  {
    component: (
      <Slide>
        <Overlay>
          <Text style={{ marginBottom: 20 }}>
            Apart from the financial side, the challenge brought a lot fun in
            the uncertain times, when (almost) everyone was stuck at home. It
            also reminded us that everyone can rap, although the result may be
            often surprising.
          </Text>
          <Text>That should be credited to Solar, who started it all 👏</Text>
        </Overlay>
      </Slide>
    ),
  },
  {
    depthToShow: 100,
    component: (
      <Slide>
        <Overlay centered>
          <Link href={'https://www.siepomaga.pl/hot16challenge'}>
            link to the money collection
          </Link>
          <Link href={'https://hot16challenge.network/'} m={4}>
            original challenge website
          </Link>
          <Flex alignItems={'center'} justifyContent={'center'} mb={2}>
            <h2 style={{ margin: 0 }}>Share </h2>
            <iframe
              src="https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2Fwww.sawka.pro%2Fhot16challenge2&layout=button_count&size=large&appId=865485733593064&width=88&height=28"
              width="130"
              style={{ marginLeft: 15, marginTop: 5, marginRight: 10 }}
              height="40"
              scrolling="no"
              frameBorder="0"
              allowTransparency="true"
              allow="encrypted-media"
            ></iframe>
          </Flex>

          <Button
            as={'a'}
            href={'/hot16challenge2-chart-en'}
            style={{ textDecoration: 'none', textAlign: 'center' }}
          >
            Browse the detailed chart
          </Button>
          <Button
            variant={'outline'}
            style={{ marginTop: 20 }}
            as={'a'}
            href={'/hot16challenge2-en'}
          >
            Restart
          </Button>
        </Overlay>
      </Slide>
    ),
  },
  {
    component: <div></div>,
  },
]

export const getSlide = (stepIndex = 0) => {
  return {
    transform: transformScale(1),
    filter: (e) => e.data.extra && e.data.extra.responseUrl,
    depthToShow: 100,
    slideCallback: (width, height) => {
      //TODO
    },
    ...slides[stepIndex],
  }
}

export default slides
